import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginatedResponse } from '@app/models/response';
import { ClusterDTO, MaterialTableLineDTO } from '@app/models/material';
import { ValuesTechAttribute } from '@app/models/values-tech-attribute';
import { AffiliateMaterial } from '@app/models/affiliate-material';
import { ResultType } from '@app/models/result-type';
import { AttributeFilter } from '@pages/search/models/search-criteria';
import { MaterialCategory } from '@/app/models/material-category';
import { CustomEncoder } from '@/app/shared/services/custom-encoder.service';
import { AllMaterialTableLineDTO } from '@/app/models/all-material';
import { AllMaterialParams } from '@/app/models/value';

/**
 * Page request information.
 */
export interface PageRequest {
    /** Page index (starts at 0) */
    page: number;
    /** Page size */
    size: number;

    /** Optional sort column (only one sort axis at most) */
    sort?: Sort;
}

/**
 * Sorting strategy.
 */
export interface Sort {
    column: string;
    direction: 'asc' | 'desc';
}

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    basicUrl: string = environment.backendApi + '/v1/';

    private stockAlertNotifictaionHistory = new BehaviorSubject<any>(null);
    getstockAlertNotifictaions = this.stockAlertNotifictaionHistory.asObservable();

    private isAlertNotifyMessage = new BehaviorSubject<boolean>(false);
    currentAlertNotifyMessage = this.isAlertNotifyMessage.asObservable();

    constructor(private http: HttpClient) {}

    getListMaterialCategories(): Observable<MaterialCategory[]> {
        return this.http.get<MaterialCategory[]>(this.basicUrl + 'material-categories');
    }

    getListAffiliateAndMaterial(): Observable<AffiliateMaterial> {
        return this.http.get<AffiliateMaterial>(this.basicUrl + 'affiliates-materials');
    }

    getAffiliates(): Observable<string[]> {
        return this.http.get<string[]>(this.basicUrl + 'affiliates');
    }

    getMaterailGroup(materialType?: string, affiliate?: string): Observable<ClusterDTO> {
        let params = new HttpParams();
        if (materialType) {
            params = params.set('materialType', materialType);
        }
        if (affiliate) {
            params = params.set('affiliate', affiliate);
        }
        return this.http.get<ClusterDTO>(this.basicUrl + 'materials/groups', { params });
    }

    getStockDetails(affiliate?: string): Observable<any> {
        let params = new HttpParams();
        if (affiliate) {
            params = params.set('affiliate', affiliate);
        }
        return this.http.get(this.basicUrl + 'homepage', { params });
    }

    getSortByValueDashboardDetails(affiliate?: string): Observable<any> {
        return this.http.get(this.basicUrl + `homepage?affiliate=${String(affiliate)}&sortBy=value`);
    }

    getSortByQuantityDashboardDetails(affiliate?: string): Observable<any> {
        return this.http.get(this.basicUrl + `homepage?affiliate=${String(affiliate)}&sortBy=quantity`);
    }

    getSavings(
        number?: string,
        searchMtrn?: string,
        affiliate?: string,
        discipline?: string,
        woStatus?: string,
        materialType?: string,
        myAffiliateOnly?: boolean
    ): Observable<string> {
        let params = new HttpParams();
        if (number) {
            params = params.set('workOrderNumber', number);
        }
        if (searchMtrn) {
            params = params.set('matnr', searchMtrn);
        }
        if (affiliate) {
            params = params.set('country', affiliate);
        }
        if (discipline) {
            params = params.set('discipline', discipline);
        }
        if (woStatus) {
            params = params.set('woStatus', woStatus);
        }
        if (materialType) {
            params = params.set('materialType', materialType);
        }
        if (myAffiliateOnly) {
            params = params.set('myAffiliateOnly', myAffiliateOnly);
        }
        return this.http.get<string>(this.basicUrl + 'material-work-orders/cost-savings', { params });
    }

    getSavingsForSearchedMaterials(
        materialType: string | undefined,
        affiliateStock: string[] | number[],
        attributeFilter?: AttributeFilter[],
        resultType?: string,
        frameContract?: boolean,
        pageRequest?: PageRequest
    ): Observable<number> {
        let params = new HttpParams();
        if (materialType) {
            params = params.append('materialType', materialType);
        }
        params = this.addPageableQueryParams(params, pageRequest);
        if (resultType) {
            params = params.append('resultType', resultType);
        }
        if (typeof frameContract !== 'undefined') {
            params = params.append('frameContract', frameContract);
        }

        params = params.append('affiliateStock', affiliateStock.map((x) => x.toString()).join(','));

        return this.http.post<number>(this.basicUrl + 'savings/search-by-technical-attributes', attributeFilter, {
            params,
        });
    }

    getPartNumbersMatchingWithSearchText(partNumber: string): Observable<string[]> {
        let params = new HttpParams({ encoder: new CustomEncoder() });
        params = params.set('partNumber', partNumber);
        return this.http.get<string[]>(this.basicUrl + 'materials/part-numbers', { params });
    }

    getSearchedHistory(userEmail: string, pageName: string): Observable<any> {
        let params = new HttpParams();
        if (userEmail) {
            params = params.set('userEmail', userEmail);
        }
        if (pageName) {
            params = params.set('pageName', pageName);
        }
        return this.http.get<any>(this.basicUrl + 'get/previous-search', { params });
    }

    saveSearchedHistory(userEmail: string, pageName: string, searchQuery: string): Observable<string> {
        const requestBody = {
            userEmail,
            pageName,
            searchQuery,
        };
        return this.http.post(this.basicUrl + 'save-search', requestBody, { responseType: 'text' });
    }

    /**
     *
     * @param materialType
     * @param affiliateStock
     * @param attributeFilter
     * @param resultType
     * @param frameContract
     * @param pageRequest
     * @param attributesNames
     * @param attributeValues
     */

    getMaterials(
        materialType: string | undefined,
        affiliateStock: string[] | number[],
        resultType?: string,
        frameContract?: boolean,
        attributesNames?: string[],
        attributeValues?: string[],
        pageRequest?: PageRequest
    ): Observable<any> {
        resultType = resultType ?? ResultType.EXACT;
        let param = new HttpParams({ encoder: new CustomEncoder() })
            .set('materialType', materialType ?? '')
            .set('resultType', resultType)
            .set('frameContract', frameContract ?? false)
            .set('affiliateStock', affiliateStock ? affiliateStock.join(',') : '')
            .set('attributeNames', attributesNames ? attributesNames.join(',') : '')
            .set('attributeValues', attributeValues ? attributeValues.join(',') : '');
        param = this.addPageableQueryParams(param, pageRequest);
        const options = {
            params: param,
        };
        return this.http.get<any>(this.basicUrl + 'materials/search-by-technical-attributes', options);
    }

    getListValueTechnicalAttribute(materialType?: number): Observable<ValuesTechAttribute> {
        let params = new HttpParams();
        if (materialType) {
            params = params.append('materialType', materialType);
        }
        return this.http.get<ValuesTechAttribute>(this.basicUrl + 'value-technical-attribute', { params });
    }

    /**
     * get AllMaterial when we search by matnr.
     *
     * @param affiliateStock
     * @param pageRequest
     * @param matnr
     * @param affiliate
     * @param resultType
     * @param frameContract
     */

    getAllMaterialByMatnr(
        affiliateStock: string[],
        matnr: string,
        affiliate: string,
        resultType?: string,
        frameContract?: boolean,
        pageRequest?: PageRequest
    ): Observable<PaginatedResponse<MaterialTableLineDTO>> {
        let params = new HttpParams();
        params = params.append('matnr', matnr);
        params = params.append('affiliate', affiliate);

        params = this.addPageableQueryParams(params, pageRequest);

        resultType = resultType ?? ResultType.EXACT;
        params = params.append('resultType', resultType);
        params = params.append('affiliateStock', affiliateStock.map((x) => x.toString()).join(','));

        if (typeof frameContract !== 'undefined') {
            params = params.append('frameContract', frameContract);
        }
        return this.http.get<PaginatedResponse<MaterialTableLineDTO>>(this.basicUrl + 'materials', { params });
    }

    /**
     * get AllMaterial when we search by part number.
     *
     * @param affiliateStock
     * @param pageRequest
     * @param partNumber
     * @param resultType
     * @param frameContract
     */

    getMaterialsByPartNumber(
        affiliateStock: string[],
        partNumber: string,
        resultType?: string,
        frameContract?: boolean,
        pageRequest?: PageRequest
    ): Observable<PaginatedResponse<MaterialTableLineDTO>> {
        let params = new HttpParams();
        params = params.append('partNumber', window.btoa(partNumber));

        params = this.addPageableQueryParams(params, pageRequest);

        resultType = resultType ?? ResultType.EXACT;
        params = params.append('resultType', resultType);
        params = params.append('affiliateStock', affiliateStock.map((x) => x.toString()).join(','));

        if (typeof frameContract !== 'undefined') {
            params = params.append('frameContract', frameContract);
        }

        return this.http.get<PaginatedResponse<MaterialTableLineDTO>>(this.basicUrl + 'materials/search', { params });
    }

    private addPageableQueryParams(params: HttpParams, pageRequest?: PageRequest): HttpParams {
        if (!pageRequest) {
            return params;
        }

        params = params.set('size', pageRequest.size);
        params = params.set('page', pageRequest.page);

        if (pageRequest?.sort) {
            pageRequest.sort.column = SearchService.mapToServerColumn(pageRequest.sort.column);
            params = params.append('sort', `${pageRequest.sort.column},${pageRequest.sort.direction}`);
        }

        return params;
    }

    /**
     * Translates a table column header code (ex: 'quantity') into its corresponding server code (ex: 'stock.quantity').
     */
    private static mapToServerColumn(column: string): string {
        // This maps ag-grid columns to the name of the entity fields in the server.
        // A column may map to multiple fields.
        const serverSortPerColumn: { [key: string]: string[] } = {
            affiliate: ['affiliate.name'],
            availability: ['stock.availability'],
            quantity: ['stock.quantity'],
            priceAndDate: ['price'],
            manufacturer: ['manufacturer.name'],
            eta: ['eta.average'],
        };
        return (serverSortPerColumn[column] || [column]).join(',');
    }

    private static mapToServerColumnNames(column: string): string {
        // This maps ag-grid columns to the name of the entity fields in the server.
        // A column may map to multiple fields.
        const serverSortPerColumn: { [key: string]: string[] } = {
            affiliate: ['affiliate'],
            qtystock: ['qtystock'],
            shortDescription: ['shortDescription'],
            longDescription: ['longDescription'],
            materilNumber: ['materialNumber'],
        };
        return (serverSortPerColumn[column] || [column]).join(',');
    }

    private addPageableQueryParamNames(params: HttpParams, pageRequest?: PageRequest): HttpParams {
        if (!pageRequest) {
            return params;
        }

        params = params.set('pageSize', pageRequest.size);
        params = params.set('offset', pageRequest.page);

        if (pageRequest?.sort) {
            pageRequest.sort.column = SearchService.mapToServerColumnNames(pageRequest.sort.column);
            params = params.append('sort', `${pageRequest.sort.column},${pageRequest.sort.direction}`);
        }

        return params;
    }

    getAllMaterialsDisplayData(allMaterialParams: AllMaterialParams): Observable<PaginatedResponse<AllMaterialTableLineDTO>> {
        let params = new HttpParams();
        params = this.addPageableQueryParamNames(params, allMaterialParams.pageRequest);

        if (allMaterialParams.affiliate) {
            params = params.set('affiliate', allMaterialParams.affiliate);
        }
        if (allMaterialParams.longDescsearch) {
            params = params.set('longDesc', allMaterialParams?.longDescsearch);
        }
        if (allMaterialParams.shortDescsearch) {
            params = params.set('shortDesc', allMaterialParams?.shortDescsearch);
        }
        if (allMaterialParams.materialNumberr) {
            params = params.set('materialNumber', allMaterialParams.materialNumberr);
        }
        return this.http.get<PaginatedResponse<AllMaterialTableLineDTO>>(this.basicUrl + 'materials/allDisplay', { params });
    }

    getSearchAllMaterials(
        affiliate: string,
        shortDescsearch?: string,
        longDescsearch?: string,
        pageRequest?: PageRequest
    ): Observable<any> {
        let params = new HttpParams({ encoder: new CustomEncoder() });
        params = this.addPageableQueryParamNames(params, pageRequest);
        if (affiliate) {
            params = params.set('affiliate', affiliate);
        }

        if (shortDescsearch) {
            params = params.set('shortDesc', shortDescsearch);
        }

        if (longDescsearch) {
            params = params.set('longDesc', longDescsearch);
        }

        const options = {
            params,
        };

        return this.http.get<any>(this.basicUrl + 'materials/searchByLongDesc', options);
    }

    saveNotificationHistory(username: string, materialNumber: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const requestBody = {
            username,
            materialNumber,
        };
        return this.http.post(this.basicUrl + 'materials/addNotifiedUserMaterial', requestBody, { responseType: 'text', headers });
    }

    getAlertUser(username: string): Observable<any> {
        let params = new HttpParams();
        if (username) {
            params = params.append('username', username);
        }
        return this.http.get(this.basicUrl + 'materials/alertUser', { params });
    }

    updateAlertMesage(data: boolean): void {
        this.isAlertNotifyMessage.next(data);
    }

    updateAlertNotification(data: any): void {
        this.stockAlertNotifictaionHistory.next(data);
    }
}
